import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import PageSplash from "../../components/PageSplash/PageSplash"
import PreHeading from "../../components/PreHeading"
import IconRow from "../../components/IconRow/IconRow"
import IconRowItem from "../../components/IconRow/IconRowItem"
import { ValueProp, ValuePropHeader } from "../../components/ValueProp/ValueProp"
import { MediaAndText } from "../../components/ImageAndText/ImageAndText"

import LoginIcon from "../../images/icons/log-in.inline.svg"
import PieIcon from "../../images/icons/pie.inline.svg"
import ChevRightIcon from "../../images/icons/chev-right.inline.svg"
import MaximizeIcon from "../../images/icons/maximize.inline.svg"
import AwardIcon from "../../images/icons/award.inline.svg"
import ReloadIcon from "../../images/icons/reload.inline.svg"
import LayersIcon from "../../images/icons/layers.inline.svg"
import GlobeIcon from "../../images/icons/globe.inline.svg"
import TabletIcon from "../../images/icons/tablet.inline.svg"
import FilterIcon from "../../images/icons/filter.inline.svg"
import DownloadCloudIcon from "../../images/icons/download-cloud.inline.svg"
import MessageIcon from "../../images/icons/message.inline.svg"
import CodepenIcon from "../../images/icons/codepen.inline.svg"
import FeatherIcon from "../../images/icons/feather.inline.svg"
import UsersIcon from "../../images/icons/users.inline.svg"

import CustomerSlider from "../../components/CustomerSlider"
import ScreenshotEn from "../../images/pages/products/sales/screenshot-de"
import Seo from "../../components/Seo"

import Style from "./sales.module.css"
import { SupportDe } from "../../components/Support"
import { StartNowDe } from "../../components/StartNow"
import { PageType } from "../../index"
import ProductNav from "../../components/ProductNav/ProductNav"

const SalesDe: PageType = ({ location }) => {
    const {
        splash,
    } = useStaticQuery(graphql`
        query SalesDe {
            splash: file(relativePath: { eq: "sales.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440, maxHeight: 680) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return <Layout location={location}>
        <Seo
            title="Markenbotschafter durch Expertise schaffen"
            description="Vertrieb stärken durch Markenbotschafter als Experten erklärungsbedürftiger Produkte."
            keywords="Markenbotschafter, erklärungsbedürftige Produkte"
        />
        <ProductNav />
        <PageSplash
            preHeading="Ihre Vertreter stärken"
            heading="Markenbotschafter schaffen"
            cta="Mehr erfahren"

            image={splash}
        />
        <span aria-hidden id="content" />
        <ValueProp>
            <ValuePropHeader
                preHeading="Vertrieb"
                heading="Wer gut versteht, kann gut verkaufen"
            >
                <p>
                    Geben Sie Ihren Vertretern die Stärke, die sie brauchen, um erfolgreich zu verkaufen. Ihr Anspruch lautet Produktwissen abrufbar zu haben und nicht nachschlagen zu müssen. Schenken Sie Ihrem Kunden Ihr Wissen auf einem Serviertablett und Ihre Kunden erhalten eine exzellente Beratung mit Schleifchen.<br />
                    Aus erklärungsbedürftigen Produkten werden attraktive Begehrlichkeiten. Aus Ihrem Vertrieb, Partnern und Vermittlern werden Markenbotschafter. Aus Ihren Kunden werden Fans.
                </p>
            </ValuePropHeader>

            <IconRow>
                <IconRowItem icon={<LoginIcon />}>
                    Erklärungsbedürftige Produkte vermitteln
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    In den Köpfen bleiben
                </IconRowItem>
                <IconRowItem icon={<ChevRightIcon />}>
                    Sichtbarkeit erhöhen
                </IconRowItem>
                <IconRowItem icon={<MaximizeIcon />}>
                    Unternehmens-Branding
                </IconRowItem>
            </IconRow>
        </ValueProp>
        <section className="section-x-margin">
            <div className="flex flex-wrap desktop:flex-no-wrap items-center max-w-6xl px-4 m-auto">
                <div className="w-full desktop:w-2/3 m-auto">
                    <PreHeading className="mb-4">Statistiken</PreHeading>
                    <h2>Messbare Ergebnisse für Ihre Entscheidungen</h2>
                </div>
                <div className="w-full desktop:w-1/3 m-auto mb-0">
                    <p className="mb-0">
                        Diese Live-Analysen stehen Ihnen jederzeit zur Verfügung, sodass Sie wissen, was andere aktuell wissen. Aktivitäten und Handlungen werden sichtbar und für Sie eine verlässliche datenbasierte Grundlage für markt- und  betriebsrelevante Entscheidungen.
                    </p>
                </div>
            </div>

            <div className={`${Style.statisticsImage} ${Style.statisticsImageDe}`} />
        </section>
        <section className="section-x-margin">
            <MediaAndText
                noImageLimit
                imageClassName="tablet:px-12 mobile:px-4"
                image={<ScreenshotEn className="w-full pointer-events-none" />}
            >
                <PreHeading>Gamification</PreHeading>
                <h2>Spieltrieb, Belohnungen und Anreize</h2>
                <p>
                    Die Werbewirkungsforschung weiß heute:<br />
                    Wer Neugier und Spieltrieb bedient, bleibt in guter Erinnerung. Kombiniert mit Incentives für aktive Teilnehmer ist QUADIO ein effizientes Marketing-Instrument. Und ein besonderes Marken-Erlebnis.
                </p>
                <ul className="list-blue-bullet mt-8">
                    <li>Effektive Methoden</li>
                    <li>Gamification</li>
                    <li>Wissenschaftlich belegt</li>
                </ul>
            </MediaAndText>
        </section>
        <SupportDe>
            <li>Projektbegleitung und Strategieberatung</li>
            <li>Traffic relevante Marketinganalysen</li>
            <li>Produktspezifische Schulungserstellung</li>
            <li>Requirements Engineering</li>
            <li>Einführung, Betrieb und technischer Support</li>
            <li>Akkreditierungen und Zertifizierungen</li>
            <li>Erfolgsanalysen</li>
            <li>Individuelle Entwicklungen</li>
        </SupportDe>
        <section className="section-x-margin section-x-padding">
            <PreHeading>Overview</PreHeading>
            <h2>Features</h2>

            <IconRow className="mt-16 mobile:mt-12" fluid>
                <IconRowItem icon={<LayersIcon />}>
                    Quiz-Trainings
                </IconRowItem>
                <IconRowItem icon={<PieIcon />}>
                    Statistiken
                </IconRowItem>
                <IconRowItem icon={<GlobeIcon />}>
                    Online-Schulungen
                </IconRowItem>
                <IconRowItem icon={<TabletIcon />}>
                    Von überall und jederzeit
                </IconRowItem>
                <IconRowItem icon={<ReloadIcon />}>
                    Automatisierte Zertifikate
                </IconRowItem>
                <IconRowItem icon={<FilterIcon />}>
                    Aktuelle Informationen
                </IconRowItem>
                <IconRowItem icon={<DownloadCloudIcon />}>
                    Download von Informationsbroschüren
                </IconRowItem>
                <IconRowItem icon={<AwardIcon />}>
                    Attraktive Gewinnspiele und Aktionen
                </IconRowItem>
                <IconRowItem icon={<MessageIcon />}>
                    Votings und Feedback
                </IconRowItem>
                <IconRowItem icon={<CodepenIcon />}>
                    Live Daten und Analysen
                </IconRowItem>
                <IconRowItem icon={<FeatherIcon />}>
                    Content Management System
                </IconRowItem>
                <IconRowItem icon={<UsersIcon />}>
                    Customer Relationship Management
                </IconRowItem>
            </IconRow>
        </section>
        <section className="section-x-margin">
            <CustomerSlider />
        </section>
        <StartNowDe />
    </Layout>
}

export default SalesDe
